/**
 * Cria um elemento de acordo com um namespace
 * @param {string} ns namespace
 * @param {string} elem elemento
 * @param {object} attrs objeto de atributos
 * @param {(Element|string)[]} children elementos filhos
 * @returns {Element}
 */
function hGen (ns, elem, attrs, children = []) {
    let el = document.createElementNS(ns, elem);
    if (!attrs) attrs = {};
    for (let attr in attrs) {
        if (attr === 'style' && typeof attrs.style === 'object') {
            for (let style in attrs.style) {
                el.style[style] = attrs.style[style];
            }
        } else {
            el.setAttributeNS(null, attr, attrs[attr]);
        }
    }
    for (let c of children) {
        if (typeof c === 'string') el.appendChild(document.createTextNode(c));
        else el.appendChild(c);
    }
    return el;
}

/**
 * Cria um elemento XHTML
 * @param {string} elem elemento
 * @param {object} attrs objeto de atributos
 * @param {(Element|string)[]} children elementos filhos
 * @returns {HTMLElement}
 */
const h = (elem, attrs, children) => hGen('http://www.w3.org/1999/xhtml', elem, attrs, children);

/**
 * Cria um elemento SVG
 * @param {string} elem elemento
 * @param {object} attrs objeto de atributos
 * @param {(Element|string)[]} children elementos filhos
 * @returns {SVGElement}
 */
const hSvg = (elem, attrs, children) => hGen('http://www.w3.org/2000/svg', elem, attrs, children);

module.exports = {
    h, hSvg,
}
